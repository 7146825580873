import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgTennessee = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 -20 64 64"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="currentColor"
      d="M35.0458 9.77463L37.5298 8.88737L42.3208 4.98363L45.692 2.49963L46.1353 1.79012L46.4015 1.52387L47.732 0.548124L47.9983 0.015625L12.3313 2.32263L12.5975 3.65313L4.16902 4.18562L3.28177 7.37988L3.19327 9.24288L1.77352 10.2186L0.797774 12.5256L-0.000976562 15.9861L31.496 13.8569V13.7684C31.9393 12.2601 32.0285 12.1716 32.2948 11.9939L34.7788 9.95312C34.8673 9.86462 34.9565 9.77538 35.045 9.77538L35.0458 9.77463Z"
    />
  </svg>
);

export default SvgTennessee;
