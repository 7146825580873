import { setJurisdictionPerServiceKey } from 'src/api/duke/jurisdiction';
import { JurisdictionCodes } from 'src/components/Common/Jurisdiction/types';

const setJurisdictionCookie = async (code: (typeof JurisdictionCodes)[number], isPNG?: boolean) => {
  const [stateAbbreviation, serviceKey] = code.split(/(?=[0])/);

  // The cookie returned from the request must be used on a secure origin (https) so
  // for local development we need to set the cookie on the client here
  if (process.env.NODE_ENV === 'development') {
    const oneYear = 31536000;
    const value = encodeURIComponent(JSON.stringify({ JU: code, SEGMENT: 'RES' }));
    document.cookie = `DEC=${value}; path=/; domain=.duke-energy.com; max-age=${oneYear}; samesite=strict`;
  }

  // let the API set the cookie on prod
  const cookie = await setJurisdictionPerServiceKey(stateAbbreviation, serviceKey, isPNG);
  return cookie;
};

export default setJurisdictionCookie;
