import React, { useEffect, useRef } from 'react';
import { useSitecoreContext } from 'src/lib/Hooks/SitecoreContextFactory';
import getCookies, { getCookiesMap } from 'src/lib/Utils/Cookies';
import { getSectionSegment } from 'src/lib/Utils/helpers';
import { Jurisdictions } from 'src/lib/constants';
import { Mutate } from 'src/types/routeHandler';
import { Route } from 'src/types/sitecore-jss';
import { AutocompleteString } from 'src/types/util';
import { getNavItems } from './getNavItems';

interface Status extends RouteData {
  isAuthenticated?: boolean;
  isImpersonated: boolean;
  externalID?: string;
  jurisdiction?: {
    serviceKey: '01' | '02' | '01PNG';
    jurisdictionCode: keyof typeof Jurisdictions;
    abbreviation: 'IN' | 'NC' | 'SC' | 'FL' | 'KY' | 'OH' | 'TN';
    stateName: string;
  };
  language?: AutocompleteString<'en' | 'es'>;
  site?: {
    name: string;
  };
  page: {
    jurisdictions?: (keyof typeof Jurisdictions)[];
    isJurisdictionallyLocked: boolean;
    navItems?: any;
    segment?: 'BUS' | 'RES';
  };
  itemPath: string;
}

type Context = {
  status: Status;
  mutate: Mutate;
};

type RouteData = { route: Route | null };

const AppContext = React.createContext<Context>({
  mutate: () => new Promise(resolve => resolve()),
  status: {
    itemPath: '',
    page: {
      isJurisdictionallyLocked: false,
    },
  },
} as Context);

const useAppContext = () => React.useContext(AppContext);

const getPrimaryNav = (routeData: RouteData) =>
  routeData?.route?.placeholders?.['jss-public-header']?.find(
    ({ componentName }) => componentName === 'PrimaryNav' || componentName === 'FID Header'
  );

const getSegment = (primaryNav: ReturnType<typeof getPrimaryNav>) =>
  (primaryNav?.fields?.userSegment?.value ||
    getSectionSegment()) as Context['status']['page']['segment'];

const getJurisdiction = (primaryNav: ReturnType<typeof getPrimaryNav>) =>
  primaryNav?.fields?.selectedJurisdiction as Context['status']['jurisdiction'];

// Check for de.identity cookie to determine isImpersonated
const getIsImpersonated = () => {
  const cookie = getCookies();

  const hasImpersonationCookie = !!cookie?.['de.identity'];

  const isImpersonated = hasImpersonationCookie && !!getExternalID();

  return isImpersonated;
};

const getIsAuthenticated = (primaryNav: ReturnType<typeof getPrimaryNav>) => {
  const isAuthenticated = primaryNav?.fields
    ?.isAuthenticated as Context['status']['isAuthenticated'];
  const isImpersonated = getIsImpersonated();

  // If the user is impersonating, the "isAuthenticated" value from sitecore will be false and we must instead defer to checking for the presence of the  "de.identity" cookie
  return isAuthenticated || isImpersonated;
};

const getExternalID = () => {
  const cookie = getCookies();
  const identityCookie = cookie?.['de.identity'];
  const externalId = getCookiesMap(identityCookie, 'ImpersonatedExternalId');

  return externalId?.toUpperCase() || null;
};

const getPageJurisdictions = (routeData: RouteData) =>
  routeData?.route?.fields?.Jurisdictions?.map(item => item.fields?.['Jurisdiction Code']?.value);

const getIsJurisdictionallyLocked = (routeData: RouteData) =>
  routeData?.route?.fields?.['Is Jurisdictionally Locked']?.value as boolean;

const getComponent = (routeData: RouteData) => {
  const placeholders = Object.values(routeData?.route?.placeholders || {}).flat();
  return placeholders?.find(({ componentName }) => componentName === 'NavItems');
};

const AppContextProvider = ({
  children,
  mutate,
  route,
}: React.PropsWithChildren<{
  mutate: Mutate;
  route?: string;
}>) => {
  const { context } = useSitecoreContext();
  const routeData = context;

  const prevJurisdiction = useRef<string | undefined>();

  const primaryNav = getPrimaryNav(routeData);
  // current user jurisdiction settings
  const jurisdiction = getJurisdiction(primaryNav);
  // current user authenticated status
  const isAuthenticated = getIsAuthenticated(primaryNav);
  // current user impersonated status
  const isImpersonated = getIsImpersonated();
  // get externalId (email) from de.identity cookie if user isImpersonated
  const externalID = isImpersonated ? getExternalID() : null;
  // isJurisdictionallyLocked: tells us that some jurisdiction is required to be set, but it doesn't matter which
  const isJurisdictionallyLocked = getIsJurisdictionallyLocked(routeData);
  // jurisdictions: tells us the page is limited to specific jurisdictions
  const jurisdictions = getPageJurisdictions(routeData);
  // section segment
  const segment = getSegment(primaryNav);
  // navItems used for SecondaryNav
  const navItems = getNavItems(
    getComponent(routeData),
    jurisdiction?.jurisdictionCode,
    isAuthenticated,
    route
  );

  const status = {
    ...routeData,
    isAuthenticated,
    isImpersonated,
    externalID,
    jurisdiction,
    page: {
      route,
      jurisdictions,
      isJurisdictionallyLocked,
      navItems,
      segment,
    },
  };

  // TODO Remove need to reload entire page to refresh App Component jurisdiction.
  // Dispatch new CustomEvent('jurisdictionchange', { detail: { ju: jurisdictionCode }}) instead and listen for the event
  // in the application component.
  useEffect(() => {
    if (prevJurisdiction.current) {
      const didChange = prevJurisdiction.current !== status.jurisdiction?.jurisdictionCode;

      if (didChange) {
        window.dispatchEvent(
          new CustomEvent('jurisdictionchange', {
            detail: { ju: status.jurisdiction?.jurisdictionCode },
          })
        );
      }
    }
    prevJurisdiction.current = status.jurisdiction?.jurisdictionCode;
  }, [status.jurisdiction?.jurisdictionCode]);

  return <AppContext.Provider value={{ mutate, status }}>{children}</AppContext.Provider>;
};

export { AppContextProvider, AppContext as default, useAppContext };
