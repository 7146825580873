import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';
import setJurisdictionCookie from 'src/lib/Utils/setJurisdictionCookie';
import { JurisdictionCodes } from '../Jurisdiction/types';

const PageLoad = () => {
  const appContext = useAppContext();
  const { status } = useAppContext();
  const urlSearchParams = new URLSearchParams(useLocation().search);
  const params = Object.fromEntries(urlSearchParams.entries());
  const jur: any = params?.jur;

  // determine if PNG site by site name or templateName to hit JurisdictionSelectorPng endpoint
  const setPNGcookie =
    status.site?.name === 'jsspng' || status.route?.templateName === 'JSS PNG App Route';

  useEffect(() => {
    if (jur && JurisdictionCodes.indexOf(jur.toUpperCase()) !== -1) {
      const doFetch = async () => {
        await setJurisdictionCookie(jur, setPNGcookie);
        await appContext.mutate();
      };
      doFetch();
    }
  }, []);
  return null;
};

export default PageLoad;
