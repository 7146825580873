import LazyImage from 'src/components/Common/LazyImage';
import SvgLoader from 'src/components/Common/SvgLoader';
import { TriggerTypes } from '../types';

const Trigger = ({
  isActive,
  containerRef,
  handleClick,
  item,
  panelId,
  triggerId,
}: TriggerTypes) => {
  return (
    <h3
      className={`relative block py-16 md:py-24 w-1/2 sm:w-1/3 text-xl text-teal-darker ${item.classes.containerClass}`}
      data-testid="panel-container"
      ref={containerRef}
    >
      <button
        aria-controls={triggerId}
        aria-expanded={isActive}
        className={`relative flex flex-col items-center w-full h-full px-12 md:px-16 no-underline hover:underline ${item.classes.buttonClass}`}
        data-testid="panel-button"
        id={panelId}
        onClick={handleClick}
        type="button"
      >
        <LazyImage {...item.icon} aria-hidden={true} className="js-image icon-80 md:icon-128" />
        <span className="block flex-grow mt-8 lg:mt-12 line-clamp-4">{item.title}</span>
        <SvgLoader
          className={`mt-8 lg:mt-12 w-16 lg:w-20 transform duration-300 ${
            isActive ? 'scale-y-flip' : ''
          }`}
          color="text-blue"
          name="ArrowDown"
          sizeConstraints={false}
        />
      </button>
      <span
        className={`absolute right-0 bottom-0 left-0 -mb-16 transition-all ease-in-out duration-300 ${
          isActive ? 'opacity-1' : 'opacity-0'
        }`}
      >
        <SvgLoader
          className="mx-auto w-24"
          color="text-gray-lighter"
          name="Caret"
          sizeConstraints={false}
        />
      </span>
    </h3>
  );
};

export default Trigger;
