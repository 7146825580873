import { useEffect } from 'react';
import { useStep } from 'src/components/Common/Jurisdiction/context';
import { JurisdictionCodes, StepProps } from 'src/components/Common/Jurisdiction/types';
import { LoadingDots } from 'src/components/Common/LoadingOverlay';
import { useNavContext } from 'src/components/Common/NavWrapper/context';
import track from 'src/lib/Analytics';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';
import setJurisdictionCookie from 'src/lib/Utils/setJurisdictionCookie';
import { Header } from './index';

const isString = (value: unknown): value is (typeof JurisdictionCodes)[number] =>
  JurisdictionCodes.some(item => item === value);

const Loading = ({ headline, id, subhead, ...rest }: StepProps) => {
  const appContext = useAppContext();
  const { status } = useAppContext();
  const navContext = useNavContext();
  const { set, whichState } = useStep();

  const handleAnalytics = () => {
    track.component({
      action: whichState?.name.split('-').join('|') || '',
      category: 'jurisdiction_intercept',
      guid: id,
      label: headline?.value,
    });
  };

  // determine if PNG site by site name or templateName to hit JurisdictionSelectorPng endpoint
  const setPNGcookie =
    status.site?.name === 'jsspng' || status.route?.templateName === 'JSS PNG App Route';

  useEffect(() => {
    const code = whichState?.code;
    if (isString(code)) {
      const doFetch = async () => {
        await setJurisdictionCookie(code, setPNGcookie);
        await appContext.mutate();
        handleAnalytics();
        navContext.dispatch('close');
      };
      doFetch();
    } else {
      set('Error');
    }
  }, []);

  return (
    <>
      <Header
        {...rest}
        headline={headline}
        subhead={{
          ...subhead,
          value: `${subhead?.value ?? ''} <b>${whichState?.name ?? ''}</b>`,
        }}
      />
      <div className="flex justify-center py-32 mx-16">
        <LoadingDots />
      </div>
    </>
  );
};

export default Loading;
