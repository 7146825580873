import Logger from 'src/lib/Utils/Logger';

const setJurisdictionPerServiceKey = async (
  stateAbbreviation: string,
  serviceKey: string,
  isPNG?: boolean
) => {
  try {
    const response = await fetch(
      `/api/${
        isPNG ? 'JurisdictionSelectorPng' : 'JurisdictionSelector'
      }/setJurisdictionPerServiceKey`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ stateAbbreviation, serviceKey }),
      }
    );

    return await response.json();
  } catch (error) {
    Logger(error, { message: 'An error occured in setJurisdictionPerServiceKey' });
    throw error;
  }
};

export { setJurisdictionPerServiceKey };
