import { CardProps } from '../types';

const dynamicClasses = (length: number, index: number) => {
  const isFiveSixOrNineCards = [5, 6, 9].some(itemCount => length === itemCount);
  const addOnePxMargin = isFiveSixOrNineCards && (index === 0 || index === 3) ? 'lg:ml-px' : '';

  const isFourCards = length === 4 ? 'md:ml-px lg:ml-0' : '';

  const isFiveOrSixCardsBigIcon =
    [5, 6].some(itemCount => length === itemCount) && index === 0 ? 'lg:ml-px' : '';
  return { isFiveOrSixCardsBigIcon, addOnePxMargin, isFourCards };
};

const displayBehavior = (length: CardProps['length'], index: number) => {
  const variantStyles = {
    bigicon: {
      imgClasses: 'icon-76 sm:icon-122 mx-auto',
      imgSizes: '(min-width: 640px) 194px, 120px',
      imgSrcSet: [{ w: 120 }, { w: 240 }, { w: 194 }, { w: 388 }],
      item: `${dynamicClasses(length, index).isFiveOrSixCardsBigIcon} ${
        dynamicClasses(length, index).isFourCards
      }`,
    },
    centered: {
      imgClasses:
        'js-centered-img object-cover aspect-1/1 icon-80 sm:icon-128 sm:mx-auto rounded-full',
      imgSizes: '(min-width: 640px) 194px, 120px',
      imgSrcSet: [{ w: 120 }, { w: 240 }, { w: 194 }, { w: 388 }],
      item: `${dynamicClasses(length, index).addOnePxMargin} ${
        dynamicClasses(length, index).isFourCards
      }`,
    },
    rectangular: {
      imgClasses:
        'js-rectangular-img object-cover aspect-1/1 sm:aspect-16/10 w-76 sm:w-full h-76 sm:h-auto rounded-md',
      imgSizes: '(min-width: 640px) 280px, 120px',
      imgSrcSet: [{ w: 120 }, { w: 240 }, { w: 280 }, { w: 560 }],
      item: `${dynamicClasses(length, index).addOnePxMargin} ${
        dynamicClasses(length, index).isFourCards
      }`,
    },
  } as const;

  return variantStyles;
};

export { displayBehavior };
