import { Text } from '@sitecore-jss/sitecore-jss-react';
import Button from 'src/components/Common/@electron/Button';
import { PrimayNavProps } from 'src/components/Common/@electron/PrimaryNav/types';
import SvgLoader from 'src/components/Common/SvgLoader';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';
import { SmallButton } from './SmallButton';

type PublicButtonProps = Pick<PrimayNavProps, 'variant'> & {
  text: JSS.TextField;
  onClick: () => void;
  href?: string;
};

/** Button displayed when user is not signed-in */
const PublicButton = (props: PublicButtonProps) => {
  // determine if PNG site by site name or templateName to update the background color
  const { status } = useAppContext();
  const backgroundColor =
    status.site?.name === 'jsspng' || status.route?.templateName === 'JSS PNG App Route'
      ? 'png-background'
      : 'bg-teal-darker';

  const { variant, text, onClick, href } = props;
  const smallProps = { text, onClick, href };

  if (variant === 'small') {
    return <SmallButton {...smallProps} />;
  }

  return (
    <li className="relative">
      <Button
        aria-haspopup={true}
        className={`js-sign-in-btn flex items-center py-6 lg:py-8 text-sm whitespace-nowrap px-16 rounded-md ${backgroundColor} text-teal-darker hover:bg-blue transform transition-all duration-300 hover:shadow hover:-translate-y-2`}
        custom={true}
        onClick={onClick}
        href={href as string}
      >
        <SvgLoader
          className="mr-6 h-16"
          name="Individual"
          color="text-white"
          aria-hidden="true"
          focusable="false"
        />
        <span className="mt-2 text-white">
          <Text field={text} />
        </span>
      </Button>
    </li>
  );
};

export { PublicButton };
