import { Text } from '@sitecore-jss/sitecore-jss-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import componentFactory from 'src/componentFactory';
import CallToAction from 'src/components/Common/@electron/CallToAction';
import LazyImage from 'src/components/Common/LazyImage';
import track from 'src/lib/Analytics';
import useQueryParam from 'src/lib/Hooks/useQueryParam';
import { IsActiveFn, JSSTabsProps, LabelProps, TabItemProps } from './types';

const Item = ({ item }: TabItemProps) => {
  const Component = componentFactory(item['Component Type']?.value);
  return <Component fields={item.items[0].fields} />;
};

const itemAmountClasses = (hasManyItems: boolean) => {
  return hasManyItems
    ? ({
        parent: 'snap sm:snap-none overflow-x-scroll scrollbar-none md:justify-center',
        child: 'snap-start w-1/29',
      } as const)
    : ({ parent: 'justify-center', child: 'w-1/3' } as const);
};

const itemActiveClasses = (isActive: boolean) => {
  return isActive
    ? ({
        label: 'font-bold',
        input: 'cursor-default',
        img: 'js-active icon-48',
        span: 'scale-100',
      } as const)
    : ({
        label: 'hover:bg-teal-lighter',
        input: 'cursor-pointer',
        img: 'icon-30',
        span: 'scale-0',
      } as const);
};

const Label = ({ className, id, image, isActive, onClick, text }: LabelProps) => {
  const classes = itemActiveClasses(isActive);

  return (
    <button
      aria-controls={`panel-${id}`}
      aria-selected={isActive}
      className={`relative flex flex-col justify-end items-center text-center text-teal-darker px-16 pt-16 md:w-1/5 flex-shrink-0 ${className} ${classes.label}`}
      id={`tab-${id}`}
      onClick={onClick}
      role="tab"
      tabIndex={isActive ? 0 : -1}
      type="button"
    >
      {image && (
        <LazyImage
          aria-hidden={true}
          className={`mb-6 transition-all duration-150 origin-bottom ${classes.img}`}
          style={{ marginTop: isActive ? '' : '18px' }}
          {...image}
        />
      )}
      <Text field={text} tag="span" className="mb-8 line-clamp-3" />
      <span
        className={`absolute bottom-0 block w-full h-4 bg-teal-dark transform transition-all duration-150 ${classes.span}`}
      />
    </button>
  );
};

const JSSTabs = ({ rendering, ...rest }: JSSTabsProps) => {
  const items = rendering.fields?.items;
  const firstLoad = useRef(true);
  const { tabIds, classes } = useMemo(() => {
    const classes = itemAmountClasses(items?.length > 3);
    const tabIds = items?.map(({ id }) => id);
    return { tabIds, classes };
  }, [items]);
  // set active tab based on query param if present, otherwise default to first tab
  const [tab] = useQueryParam('tab');
  const [activeTab, setActiveTab] = useState(() => {
    if (tab && tabIds.includes(tab)) {
      return tabIds.indexOf(tab);
    }
    return 0;
  });

  useEffect(() => {
    if (!firstLoad.current) {
      track.component({
        category: 'tabs',
        label: rest.title?.value,
        action: tabIds[activeTab],
      });
    } else {
      firstLoad.current = false;
    }
  }, [activeTab]);

  const isActive: IsActiveFn = id => tabIds.indexOf(id) === activeTab;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.code === 'ArrowRight') {
      setActiveTab((activeTab: number) => (activeTab + 1) % tabIds.length);
    } else if (event.code === 'ArrowLeft') {
      setActiveTab((activeTab: number) => {
        return (activeTab - 1 + tabIds.length) % tabIds.length;
      });
    }
  };
  // CTA wrapper - Loop for Labels - Loop for containers with loop for nested components inside
  return (
    <CallToAction {...rest} fullWidth={true}>
      <div
        aria-orientation="horizontal"
        className={`container-3xl flex pt-16 md:pt-24 ${classes.parent}`}
        onKeyDown={handleKeyDown}
        role="tablist"
        tabIndex={0}
      >
        {items?.map(({ fields, id }, index) => (
          <Label
            className={classes.child}
            id={id}
            image={fields?.Icon}
            isActive={index === activeTab}
            key={index}
            onClick={() => setActiveTab(index)}
            text={fields?.TabName}
          />
        ))}
      </div>
      <div className="overflow-auto border-t bg-gray-lighter border-gray">
        {items?.map(({ fields, id }, index) => (
          <div
            role="tabpanel"
            id={`panel-${id}`}
            aria-labelledby={`tab-${id}`}
            key={index}
            hidden={!isActive(id)}
            className={
              fields?.Layout.value === 'HalfWidth' && isActive(id)
                ? 'flex flex-col md:flex-row'
                : ''
            }
          >
            {fields?.items?.map(({ fields }, index) => (
              <Item item={fields} key={index} />
            ))}
          </div>
        ))}
      </div>
    </CallToAction>
  );
};

export default JSSTabs;
