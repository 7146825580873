import { useStep } from 'src/components/Common/Jurisdiction/context';
import { JurisdictionProps, StepProps } from 'src/components/Common/Jurisdiction/types';
import LazyImage from 'src/components/Common/LazyImage';
import SvgLoader from 'src/components/Common/SvgLoader';
import track from 'src/lib/Analytics';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';
import { BackButton, Header } from './index';

const Map = ({
  hasZip = true,
  useZipText = 'Use my ZIP code to set my location',
  backToStatesText,
  headline,
  id,
  ...rest
}: StepProps & { hasZip?: boolean; useZipText?: string }) => {
  const { whichState, set } = useStep();
  const { status } = useAppContext();

  const handleClick = ({ code, name }: JurisdictionProps['states'][0]) => {
    if (whichState) {
      const analyticsCategory = name.split(' ').pop();
      const analyticsLabel = headline?.value?.replace(/\W[{0}]\W/, whichState?.name || '');

      set('Loading', { code, name: `${whichState.name} - ${name}` });

      track.component({
        action: 'map',
        category: `jurisdiction_intercept-${analyticsCategory}`,
        guid: id,
        label: analyticsLabel,
      });
    }
  };

  // they require to romove use zip code on the entire PNG jutisdiction selector
  const isPNG =
    status.site?.name === 'jsspng' || status.route?.templateName === 'JSS PNG App Route';

  return (
    <>
      {hasZip && <BackButton onClick={() => set('List')}>{backToStatesText}</BackButton>}
      <div className="container-xl flex flex-col gap-24 text-left md:text-center">
        <Header {...{ headline, ...rest }} />
        {Array.isArray(whichState?.code) && (
          <ul className="flex flex-col sm:flex-row sm:gap-32 justify-center text-teal-darker">
            {whichState?.code.map(({ code, name, image }, index) => (
              <li key={index} className="w-full mb-16 group">
                <button className="w-full" onClick={() => handleClick({ code, name })}>
                  <LazyImage
                    {...image}
                    alt=""
                    className="w-full bg-teal-light bg-opacity-20 group-hover:bg-opacity-100"
                  />
                  <div className="pt-8 text-left">
                    <span className="pb-2 border-b border-white group-hover:border-current">
                      {name}
                    </span>
                    <SvgLoader
                      name="Arrow"
                      className="inline ml-8"
                      size={12}
                      aria-hidden={true}
                      focusable={false}
                    />
                  </div>
                </button>
              </li>
            ))}
          </ul>
        )}
        {hasZip && !isPNG && whichState?.name !== 'Tennessee' && (
          <div className="flex justify-start md:justify-center">
            <button
              onClick={() => set('Zip')}
              className="text-teal-darker transition-all duration-150 border-b border-current hover:border-white"
            >
              {useZipText}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Map;
