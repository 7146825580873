import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useMemo } from 'react';
import Link from 'src/components/Common/Link';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';
import { makeClasses, noExtraSpaces, onlyUniqueWords } from 'src/lib/Utils/helpers';
import { ButtonOverloadsUnion, ButtonSignature, ButtonTypes } from './types';

const isText = (props: { [value: string]: unknown }): props is JSS.TextField =>
  typeof props.value === 'string';

const Button: ButtonSignature = ({
  className = '',
  custom,
  size = 'md',
  variant = 'primary',
  ...rest
}: ButtonTypes & ButtonOverloadsUnion) => {
  // determine if PNG site by site name or templateName to update the background color
  const { status } = useAppContext();
  const backgroundColor =
    status.site?.name === 'jsspng' || status.route?.templateName === 'JSS PNG App Route'
      ? 'png-background'
      : '';

  const buildClasses = useMemo(() => {
    if (variant && variant.includes('text')) {
      return `inline-flex underline hover:no-underline ${
        variant === 'text-reversed' ? 'text-white' : 'text-teal-darker'
      } ${onlyUniqueWords(className)}`;
    }

    const fromVariants = makeClasses([variant, size], 'btn-');
    const defaultClasses = noExtraSpaces(`${backgroundColor} btn ${fromVariants} ${className}`);
    if (custom) return className && onlyUniqueWords(className);
    return onlyUniqueWords(defaultClasses);
  }, [variant, size, className]);

  const props = { ...rest, className: buildClasses };

  if ('href' in props && props.href) {
    return <Link {...props} />;
  }

  // Handle JSS fields
  if ('value' in props) {
    // JSS.TextField
    if (isText(props)) {
      const { value, editable, ...rest } = props;
      return (
        <button {...rest}>
          <Text field={{ value, editable }} />
        </button>
      );
    }
    // JSS.LinkField
    return <Link {...props} />;
  }

  return <button {...props} />;
};

export default Button;
