import { useMemo } from 'react';
import SvgLoader from 'src/components/Common/SvgLoader';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';
import { CopyrightAndLinks, SiteMap, SocialLinksAndAppBadges } from './components';
import { FooterProps } from './types';

const Tagline = () => (
  <div
    className="px-16 md:px-24 py-24 md:py-32 border-b border-teal-darker"
    data-testid="footer-tagline-testid"
  >
    <div className="container-4xl">
      <SvgLoader
        sizeConstraints={false}
        aria-label="Building a Smarter Energy Future"
        className="mx-auto h-16 w-auto"
        color="text-current"
        name="DukeTagline"
      />
    </div>
  </div>
);

const Footer = ({
  badges,
  bottomLinks,
  copyright,
  columns,
  flatColumn,
  socialLinks,
  hideTagLine = false,
  variant = 'FatFooter',
}: FooterProps) => {
  const { isFat, isSkinnySocial } = useMemo(
    () => ({
      isFat: variant === 'FatFooter',
      isSkinnySocial: ['FatFooter', 'Social Toolbar and Skinny Footer'].includes(variant),
    }),
    [variant]
  );

  // determine if PNG site by site name or templateName to update the background color
  const { status } = useAppContext();
  const backgroundColor =
    status.site?.name === 'jsspng' || status.route?.templateName === 'JSS PNG App Route'
      ? 'png-background'
      : 'bg-blue';

  return (
    <div className={`${backgroundColor} text-white`}>
      {/* <div className="bg-blue text-white"> */}
      {!hideTagLine && <Tagline />}
      {isSkinnySocial && <SocialLinksAndAppBadges badges={badges} socialLinks={socialLinks} />}
      {isFat && <SiteMap columns={columns} flatColumn={flatColumn} />}
      <CopyrightAndLinks copyright={copyright} items={bottomLinks} />
    </div>
  );
};

export default Footer;
