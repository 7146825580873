import { Text } from '@sitecore-jss/sitecore-jss-react';
import RichText from 'src/components/Common/RichText_Old';
import { useSitecoreContext } from 'src/lib/Hooks/SitecoreContextFactory';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';

interface PageHeadingProps {
  heading?: JSS.TextField;
  subheading?: JSS.TextField;
  isHidden?: boolean;
}

const PageHeading = ({ heading, subheading, isHidden }: PageHeadingProps) => {
  const { context: sitecoreContext } = useSitecoreContext();
  const data = useAppContext();
  const { status } = useAppContext();

  // When the Hero or TopTasks is present on the page, PageHeading should be visually hidden since these components
  // have their own title that fulfills this visual title responsibility.
  const hasComponentTitle = sitecoreContext?.route?.placeholders?.['jss-public-main']?.some(
    ({ componentName }) => ['Hero', 'Top Tasks'].includes(componentName)
  );

  const shouldHideTitle = isHidden || hasComponentTitle;

  const isExperienceEditor = heading?.editable;
  if (!isExperienceEditor && !heading?.value && !subheading?.value) {
    return null;
  }

  const headerStyle = data.status?.route?.fields?.['Header Type']?.fields?.Phrase?.value;
  const isSimple = headerStyle === 'Simple';

  // determine if PNG site by site name or templateName to update the background color
  const textColor =
    status.site?.name === 'jsspng' || status.route?.templateName === 'JSS PNG App Route'
      ? 'png-text'
      : 'text-blue';

  return (
    <header
      className={`px-16 md:px-24 py-32 md:py-48 bg-white ${shouldHideTitle ? 'sr-only' : ''}`}
    >
      <div className="container-xl text-center">
        {heading && (
          <Text
            className={`text-3xl ${textColor}`}
            field={heading}
            tag={
              // For Simple Nav, there is an h1 tag in PrimaryNav component so we switch the tag here to a div.
              // For other navs, there is no h1 tag so we can use an h1 here.
              isSimple ? 'div' : 'h1'
            }
          />
        )}
        {subheading && (
          <RichText field={subheading} className={`text-lg ${textColor} pt-6 md:pt-12`} tag="p" />
        )}
      </div>
    </header>
  );
};

export default PageHeading;
