// Order matters here!
// Enum is exported as a value rather than a type so that members can be compared against
// eachother to determine which direction to animate.
// eg: StepNames['List'] < StepNames['Map'] === 1 < 2
enum StepNames {
  Intro,
  List,
  Map,
  Zip,
  Error,
  Loading,
}

export const JurisdictionCodes = [
  'NC01',
  'NC02',
  'SC01',
  'SC02',
  'FL01',
  'KY01',
  'OH01',
  'IN01',
  'NC01PNG',
  'SC01PNG',
  'TN01PNG',
] as const;

type JurisdictionCodesType = (typeof JurisdictionCodes)[number];

type JurisdictionProps = {
  states: {
    name: string;
    code: (typeof JurisdictionCodes)[number] | JurisdictionProps['states'][0][];
    image?: JSS.ImageField;
  }[];
  steps: {
    id: string;
    name: keyof typeof StepNames;
    headline?: JSS.TextField;
    subhead?: JSS.TextField;
    body?: JSS.TextField;
  }[];
  initialDirection?: number | `${number}`;
  backToStatesText?: string;
};

type StepProps = JurisdictionProps['steps'][number] &
  Pick<JurisdictionProps, 'states' | 'backToStatesText'>;

type ProviderProps = { initialStep?: keyof typeof StepNames };

export { StepNames };
export type { JurisdictionCodesType, JurisdictionProps, ProviderProps, StepProps };
