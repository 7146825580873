import { PrimayNavProps } from 'src/components/Common/@electron/PrimaryNav/types';
import { useNavContext } from 'src/components/Common/NavWrapper/context';
import track from 'src/lib/Analytics';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';
import { AuthButton } from './AuthButton';
import { PublicButton } from './PublicButton';

const AccountButton = ({
  auth,
  accountTxt,
  guid,
  pathname,
  profileLinks,
  signIn,
  variant,
}: Pick<PrimayNavProps, 'signIn' | 'auth' | 'accountTxt' | 'profileLinks'> & {
  guid: string;
  pathname: string;
  variant?: 'small';
}) => {
  const { dispatch } = useNavContext();

  // determine if PNG site for the PNG sign in link
  const { status } = useAppContext();
  const pngSignIn =
    status.site?.name === 'jsspng' || status.route?.templateName === 'JSS PNG App Route';

  // grab the the text prior to a slash for a small display
  const signInText =
    variant === 'small' ? { value: signIn.text.value?.split('/')[0] } : signIn.text;
  const sendAnalytics = (label?: string) => {
    track.component({
      action: pathname,
      category: 'top_level_navigation',
      label: label?.replace('/', ''),
      guid,
    });
  };

  const sendVpvAnalytics = () => {
    track({
      event: 'send-VPV',
      'vpv-name': '/vpv/de/page/sign-in/load/sign-in/sign-in-register/na/drawer',
    });
  };

  const whichState = auth ? 'AUTH' : 'DEFAULT';

  // maintenance version is handled in MAM Sign In component

  if (pngSignIn) {
    return (
      <PublicButton
        href={'https://cass.piedmontng.com/account'}
        text={signInText}
        variant={variant}
        onClick={() => {}}
      />
    );
  }

  switch (whichState) {
    case 'AUTH':
      return (
        <AuthButton guid={guid} profileLinks={profileLinks} text={accountTxt} variant={variant} />
      );

    default:
      return (
        <PublicButton
          onClick={() => {
            dispatch('open', { panel: 'right', component: 'SignIn' });
            sendAnalytics(signIn.text?.value);
            sendVpvAnalytics();
          }}
          text={signInText}
          variant={variant}
        />
      );
  }
};

export default AccountButton;
