import { isEditorActive } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { useJurisdictionSelectorData } from 'src/components/Common/JurisdictionGuard/useJurisdictionSelectorData';
import withErrorBoundary from 'src/lib/HOC/ErrorBoundary/withErrorBoundary';
import usePreventBodyScroll from 'src/lib/Hooks/usePreventBodyScroll';
import { useAppContext } from 'src/lib/Utils/Contexts/AppContext';
import Intercept from './Intercept';
import Mismatch from './Mismatch';

const useJurisdictionComparison = () => {
  const { status } = useAppContext();
  const pageJurisdictions = status.page?.jurisdictions;
  const selectedJuris = status.jurisdiction?.jurisdictionCode;
  const hasJurisdictions = pageJurisdictions?.length;
  const isLocked = status.page.isJurisdictionallyLocked;

  // EE = Experience Editor is active do not obstruct the page
  if (isEditorActive()) {
    return 'EE';
  }

  // INTERCEPT = No jurisdiction is selected on a jurisdictionally locked page
  const showIntercept = () => (hasJurisdictions || isLocked) && !selectedJuris;
  if (showIntercept()) {
    return 'INTERCEPT';
  }

  // MISMATCH = A jurisdiction is selected but it does not match any of the allowed jurisidictions
  const showMismatch = () =>
    hasJurisdictions && selectedJuris && !pageJurisdictions.includes(selectedJuris);
  if (showMismatch()) {
    return 'MISMATCH';
  }

  return selectedJuris;
};

const JurisdictionIntercept = ({ children }: React.PropsWithChildren<{}>) => {
  const { selectorProps } = useJurisdictionSelectorData();
  const jurisdictionState = useJurisdictionComparison();
  const isIntercept = jurisdictionState === 'INTERCEPT';
  // when working on PNG, comment below line to block jurisdiction guard because PNG doesn't set correct domain cookie
  usePreventBodyScroll(isIntercept);

  return (
    <>
      {children}
      {/* {when working on PNG, comment below line to block jurisdiction guard because PNG doesn't set correct domain cookie */}
      {isIntercept && <Intercept {...selectorProps} />}
    </>
  );
};

const JurisdictionMismatch = ({ children }: React.PropsWithChildren<{}>) => {
  const { mismatchProps } = useJurisdictionSelectorData();
  const jurisdictionState = useJurisdictionComparison();

  if (jurisdictionState === 'MISMATCH') {
    return <Mismatch {...mismatchProps} />;
  }

  return <>{children}</>;
};

const JurisdictionGuard = {
  Intercept: withErrorBoundary(JurisdictionIntercept),
  Mismatch: withErrorBoundary(JurisdictionMismatch),
};

export { JurisdictionGuard, JurisdictionIntercept, JurisdictionMismatch };
