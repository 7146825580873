import { SVGProps } from 'react';
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgClockCircle = ({ title, titleId, ...props }: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 7.68421C12.5523 7.68421 13 8.13193 13 8.68421V11.7967L14.7398 12.4926C15.2526 12.6977 15.502 13.2797 15.2969 13.7924C15.0918 14.3052 14.5098 14.5546 13.997 14.3495L11.6286 13.4022C11.249 13.2503 11 12.8826 11 12.4737V8.68421C11 8.13193 11.4477 7.68421 12 7.68421Z"
      fill="#4C4C4C"
    />
  </svg>
);

export default SvgClockCircle;
