import Logger from 'src/lib/Utils/Logger';

const names = {
  NC01: 'North Carolina - Duke Energy Carolinas',
  NC01PNG: 'North Carolina - Duke Energy Carolinas',
  NC02: 'North Carolina - Duke Energy Progress',
  SC01: 'South Carolina - Duke Energy Carolinas',
  SC01PNG: 'South Carolina - Duke Energy Carolinas',
  SC02: 'South Carolina - Duke Energy Progress',
};

type Response = {
  RequireAddress: boolean;
  InvalidZipCode: boolean;
  RedirectUrl: string;
  JurisdictionServiceKey?: keyof typeof names;
  Status: string;
  ErrorMsg: string;
};

type Parameters = {
  stateAbbreviation: string;
  zipCode: string;
  isPNG?: boolean;
};

type SetJurisdictionPerZipCode = ({ stateAbbreviation, zipCode, isPNG }: Parameters) => Promise<{
  code: keyof typeof names;
  name: string;
}>;

const setJurisdictionPerZipCode: SetJurisdictionPerZipCode = async ({
  stateAbbreviation,
  zipCode,
  isPNG,
}) => {
  const response = await fetch(
    `/api/${isPNG ? 'JurisdictionSelectorPng' : 'JurisdictionSelector'}/SetJurisdictionPerZipCode`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ stateAbbreviation, zipCode }),
    }
  );
  try {
    const { JurisdictionServiceKey }: Response = await response.json();

    // If we do not get a JurisdictionServiceKey in response we consider it an error
    if (!JurisdictionServiceKey) {
      throw new Error('JurisdictionServiceKey is missing');
    }
    return {
      code: JurisdictionServiceKey,
      name: names[JurisdictionServiceKey] || stateAbbreviation,
    };
  } catch (error: any) {
    // Do not log errors that resulted from a successful fetch,
    // ie getJurisdiction({ stateAbbreviation: 'NC', zipCode: '55555' }) will return
    // { ErrorMsg: "", InvalidZipCode: true, RequireAddress: false, Status: "OK" }
    // We treat this as an "Error state" in the app, but we should not log it bc
    // it is desired behavior for a request made with invalid params.
    if (error.message !== 'JurisdictionServiceKey is missing') {
      Logger(error, { message: 'Error in getJurisdiction' });
    }
    return Promise.reject(error);
  }
};

export default setJurisdictionPerZipCode;
